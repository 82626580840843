import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import CartOrderInfoPageForm from '../cart/cartOrderInfo/CartOrderInfoPageForm';
import NavigateButton from '../general/NavigateButton';
import withDataHOC from '../dataProvider/withDataHOC';
import {QUERY_PARAMS, ROUTES} from '../../constants/navigation';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Trans, t} from '@lingui/macro';
import { merge } from '../../lib/object';
import CustomersSelect from '../project/CustomersSelect';
import ButtonFetch from '../fetch/ButtonFetch';
import generalFailedPC from '../fetch/generalFailedPC';
import { createFetchCustomerDetails, createFetchOrderAdd } from '../../backend/apiCalls';
import { navigateToParametrized } from '../../lib/url';
import locationHOC from '../locationProvider/locationHOC';
import { isAddressRequired } from '../../lib/project';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { RIGHTS } from '../../constants/Rights';
import InfoAttribute from '../general/InfoAttribute';
import { Icon } from 'antd';

/**
 * @dusan
 */

class MakeOrderPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            params: {},
            customerId: null,
            customerDetails: null,
            loadingCustomer: false,
        };
    }

    changeParams = (setParams) => {
        const {params} = this.state;
        this.setState({
            params: merge(params, setParams)
        });
    }

    fetchCustomer = (newCustomerId) => {
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        this.setState({
            customerId: newCustomerId,
            loadingCustomer: true,
        });
        
        fetchHandler(
            createFetchCustomerDetails(),
            {id: newCustomerId },
            (result) => {
                this.setState({
                    customerDetails: result,
                    loadingCustomer: false,
                });
            },
        )
    }

    render() {
        const {location, [SESSION_ATTRIBUTES.RIGHTS]: rights} = this.props;
        const {params, customerDetails, customerId, loadingCustomer} = this.state;

        if(rights < RIGHTS.MARKETING)
            return null;

        let requiredNotSet = (customerId == null || params.transportTypeId == null || params.paymentMethodId == null);
        
        if(params.isProxy)
        {
            // check if proxy params are set
            if(params.finalCustomerName == null || params.finalUserName == null || params.finalAddressStreet == null 
                || params.finalAddressZipCode == null || params.finalAddressCity == null || params.finalAddressCountryId == null)
                requiredNotSet = true;
        }
        else
        {
            // standard order
            const addressRequired = isAddressRequired(rights, params.transportTypeId);
            if(addressRequired && (params.invoiceAddressId == null || params.deliveryAddressId == null))
                requiredNotSet = true;
        }

        const hasCustomer = customerId != null && customerDetails != null && loadingCustomer == false;
        let form = null;
        if(hasCustomer)
        {
            form = <CartOrderInfoPageForm
                customerDetails={customerDetails}
                params={params}
                onChangeParams={this.changeParams}
                allowProxy={true}
            />;
        }

        return <React.Fragment>
            <Helmet
                title={t`Vytvorenie novej objednávky`}
            />
            <div className="full-size-height d-flex flex-column">
                <div className="p-3 flex-item-static d-flex justify-content-between">
                    <NavigateButton
                        to={ROUTES.ORDERS}
                    >
                        <Icon type="left"/>
                        <Trans>Späť</Trans>
                    </NavigateButton>
                    <ButtonFetch
                        type="primary"
                        disabled={requiredNotSet}
                        values={{}}
                        Failed={generalFailedPC(t`Vytvorenie objednávky zlyhalo`)}
                        fetchFunction={createFetchOrderAdd({
                            id_customer: customerId,
                            trade: params.trade,
                            id_user: params.id_user,
                            id_invoice_address: params.invoiceAddressId,
                            id_delivery_address: params.deliveryAddressId,
                            id_transport_type: params.transportTypeId,
                            id_payment_method: params.paymentMethodId,
                            customer_reference: params.customerReference,
                            customer_notices: params.customerNotices,
                            dealer_notices: params.dealerNotices,
                            is_divisible: params.isDivisible,
                            id_dst_stock: params.dstStockId,
                            final_order: params.isProxy ? {
                                order_reference: params.finalOrderRef,
                                customer: {
                                    name: params.finalCustomerName
                                },
                                user: {
                                    fullname: params.finalUserName,
                                    e_mail: params.finalUserEmail,
                                    phone: params.finalUserPhone,
                                    id_language: params.finalUserLanguage,
                                },
                                delivery_address: {
                                    street: params.finalAddressStreet,
                                    zip_code: params.finalAddressZipCode,
                                    city: params.finalAddressCity,
                                    id_country: params.finalAddressCountryId,
                                }
                            } : undefined,
                        })}
                        onFinish={(orderId) => {
                            navigateToParametrized(location, ROUTES.ORDER_DETAILS, {[QUERY_PARAMS.ID_ORDER]: orderId})
                        }}
                    >
                        <Trans>Uložiť</Trans>
                    </ButtonFetch>
                </div>
                <div className={hasCustomer ? "overflow-auto" : "full-size-height"}>
                    <h3 className="text-center p-3">
                        <Trans>Vytvorenie novej objednávky</Trans>
                    </h3>
                    <div className="d-flex justify-content-center">
                        <div className="info-attribute-group">
                            <InfoAttribute
                                attributeTitle={<Trans>Organizácia: </Trans>}
                                attributeValue={
                                    <CustomersSelect
                                        value={customerId}
                                        onChange={this.fetchCustomer}
                                    />
                                }
                                isRequired={true}
                            />
                        </div>
                    </div>
                    {form}
                </div>
            </div>
        </React.Fragment>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(
    withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(
        locationHOC(MakeOrderPage)
    )
);